import 'bootstrap';        // Import Bootstrap JavaScript
import '../style/main.scss';     // Import the main SASS file
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css'; // Importing Glide CSS

//Slider 
document.addEventListener('DOMContentLoaded', function () {
    var carousel = document.querySelector('#carouselExampleIndicators');

    // Check if the carousel element exists
    if (!carousel) {
        console.warn('Carousel element not found on the page.');
        return; // Exit the function early if the carousel is not found
    }

    //carousel element exists
    var indicatorsContainer = carousel.querySelector('.carousel-indicators');
    var slides = carousel.querySelectorAll('.carousel-item');

    slides.forEach(function (slide, index) {
        var indicator = document.createElement('button');
        indicator.type = 'button';
        indicator.setAttribute('data-bs-target', '#carouselExampleIndicators');
        indicator.setAttribute('data-bs-slide-to', index);
        indicator.setAttribute('aria-label', 'Slide ' + (index + 1));
        if (index === 0) {
            indicator.classList.add('active');
            indicator.setAttribute('aria-current', 'true');
        }
        indicatorsContainer.appendChild(indicator);
    });
});



//Header Sticky
function HeaderSticky() {
window.addEventListener('scroll', function () {
    var header = document.querySelector('header');
    var logo = document.getElementById('logo');
    var sticky = header.offsetTop;

    if (window.pageYOffset > sticky) {
        header.classList.add('header-sticky');
        logo.src = 'dist/img/unicom-logo-sticky.svg'; // Switch to the sticky logo
    } else {
        header.classList.remove('header-sticky');
        logo.src = 'dist/img/unicom-logo.svg'; // Switch back to the original logo
    }
});
}




///Burger Menu
document.addEventListener('DOMContentLoaded', function () {
    const burgerMenuBtn = document.getElementById('burger-menu-btn');
    const burgerMenu = document.getElementById('burger-menu');
    const menuOverlay = document.getElementById('menu-overlay');

    burgerMenuBtn.addEventListener('click', function () {
        burgerMenu.classList.toggle('open');
        menuOverlay.classList.toggle('show');
    });

    menuOverlay.addEventListener('click', function () {
        burgerMenu.classList.remove('open');
        menuOverlay.classList.remove('show');
    });
});

// marquee
document.addEventListener('DOMContentLoaded', function () {
    const marqueeContent = document.querySelector('.marquee-content');
    
    // Check if marqueeContent exists
    if (!marqueeContent) {
        return; // If it doesn't exist, do nothing
    }

    const clone = marqueeContent.cloneNode(true);

    // Append the cloned content
    marqueeContent.parentElement.appendChild(clone);

    // Apply the alternating color scheme
    const allWords = document.querySelectorAll('.marquee-content .word');

    allWords.forEach((word, index) => {
        if (index % 2 === 0) {
            word.style.color = '#BDBFC1'; // odd indexed words (1st, 3rd, etc.)
        } else {
            word.style.color = '#000'; // even indexed words (2nd, 4th, etc.)
        }
    });
});

// glide
document.addEventListener('DOMContentLoaded', function() {
    const glideElement = document.querySelector('.glide');
    
    // Check if the .glide element exists
    if (!glideElement) {
        return; // Do nothing if the element doesn't exist
    }

    const glide = new Glide(glideElement, {
      type: 'carousel',
      startAt: 0,
      perView: 5, 
      autoplay: 1500, 
      animationDuration: 2500,
      breakpoints: {
        1600: {
            perView: 5
        },
        1200: {
            perView: 3 
        },
        800: {
            perView: 2
        },
        600: {
            perView: 1
        }
      }
    });

    glide.mount();
});

//Count Up
document.addEventListener('DOMContentLoaded', () => {
    const duration = 2000; // Duration in milliseconds
    const delay = 500; // Delay before starting the count-up animation in milliseconds
    const scrollDelay = 100; // Delay in milliseconds to check the scroll position
    const updateInterval = 100; // Increased interval for slower counting

    // Function to start the count-up animation
    function startCountUp(element) {
        const targetNumber = parseFloat(element.getAttribute('data-target'));
        const incrementSpeed = targetNumber / (duration / updateInterval); // Adjust speed to the duration

        let currentNumber = 0;
        const hasPlusSign = element.textContent.includes('+');
        
        function updateNumber() {
            if (currentNumber < targetNumber) {
                currentNumber += incrementSpeed;
                if (currentNumber > targetNumber) {
                    currentNumber = targetNumber;
                }
                element.textContent = `${hasPlusSign ? '+' : ''}${Math.round(currentNumber)}`;
                setTimeout(updateNumber, updateInterval);
            }
        }

        setTimeout(updateNumber, delay); // Delay before starting the animation
    }

    // Function to check if element is in view
    function isElementInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    // Function to handle scroll event
    function handleScroll() {
        document.querySelectorAll('.count-up').forEach(element => {
            if (isElementInViewport(element) && !element.classList.contains('counted')) {
                startCountUp(element);
                // To avoid re-triggering the animation, mark the element as processed
                element.classList.add('counted');
            }
        });
    }

    // Attach scroll event listener
    window.addEventListener('scroll', () => {
        setTimeout(handleScroll, scrollDelay);
    });

    // Initial check if elements are in view on page load
    handleScroll();
});

//area for check viewports
function checkViewport() {
    if (window.matchMedia("(min-width: 992px)").matches) {
        HeaderSticky();
    }
}
//Run HeaderSticky after checkViewport 
checkViewport();


window.addEventListener('resize', function() {
    checkViewport();
});

